import React from 'react';

export const Support = () => {
    return (
        <div className='content supportPage'>
            <div className='pageTitle'>
                <span>Support</span>
            </div>
            <div className='row supportPageContent'>
                <p>If you experiencing any operational or technical difficulties, please reach out to our Support Team.</p>

                <p><b>Email:</b> sms.noc@baway.net</p>
                <p className='br'><b>Skype:</b> live:amirabaskabirizamani_1</p>

                <p className='br'>Our team is available for you 24/7 and we will be happy to help!</p>

                <p>Yours sincerely,</p>
                <p>SMScenter.Baway</p>
            </div>
        </div>
    );
}
